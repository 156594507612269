import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getBaseUrl } from '../functions/getBaseUrl';

const BASE_URL = getBaseUrl();

export const addressesAPI = createApi({
  reducerPath: 'addressesAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchAllAddresses: build.query({
      query: () => ({
        url: '/api/addresses',
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export const settingsAPI = createApi({
  reducerPath: 'settingsAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchAllSettings: build.query({
      query: () => ({
        url: '/api/settings',
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export const domainAPI = createApi({
  reducerPath: 'domainAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchAllDomain: build.query({
      query: () => ({
        url: '/api/domain',
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export const lambicClubLinkAPI = createApi({
  reducerPath: 'lambicClubLinkAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchAllLabicClubLink: build.query({
      query: () => ({
        url: '/api/lambic-club',
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export const aboutInfoLinkAPI = createApi({
  reducerPath: 'aboutInfoLinkAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchAllAboutInfoLink: build.query({
      query: () => ({
        url: '/api/info-links',
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export const articlesAPI = createApi({
  reducerPath: 'articlesAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchAllArticles: build.query({
      query: ({ currentPage, articlesPerPage }) => ({
        url: `/api/articles?page=${currentPage}&perPage=${articlesPerPage}`,
        params: {
          articlesPerPage,
          offset: (currentPage - 1) * articlesPerPage,
        },
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export const articleAPI = createApi({
  reducerPath: 'articleAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchOneArticle: build.query({
      query: articleName => ({
        url: `/api/articles/${articleName}`,
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export const eventsAPI = createApi({
  reducerPath: 'eventsAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchAllEvents: build.query({
      query: ({ currentPage, eventsPerPage }) => ({
        url: `/api/events?page=${currentPage}&perPage=${eventsPerPage}`,
        params: {
          eventsPerPage,
          offset: (currentPage - 1) * eventsPerPage,
        },
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export const eventAPI = createApi({
  reducerPath: 'eventAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchOneEvent: build.query({
      query: eventName => ({
        url: `/api/events/${eventName}`,
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export const sitemapAPI = createApi({
  reducerPath: 'sitemapAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchSitemap: build.query({
      query: () => ({
        url: '/api/sitemap',
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});
