import React from 'react';
import classes from './SkeletonAddresses.module.scss';
import addressesClasses from '../../../pages/Addresses/Addresses.module.scss';
import Skeleton from '../Skeleton';
import SkeletonAddressCard from '../SkeletonAddressCard/SkeletonAddressCard';
import { GeneralContext } from '../../../context/generalContext';

export default function SkeletonAddresses() {
  const { subdomainIsActive } = React.useContext(GeneralContext);

  const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <>
      <div className='wrapper'>
        {!subdomainIsActive && (
          <div className={classes.search}>
            <Skeleton borderRadius='5px' width={140} height={15} isLight={false} />
          </div>
        )}
        <div className={addressesClasses.addressCards}>
          {cards?.map((_, index) => (
            <SkeletonAddressCard key={index} />
          ))}
        </div>
      </div>
      <div className={classes.map}>
        <Skeleton borderRadius='5px' width='100%' height='100%' isLight />
      </div>
    </>
  );
}
