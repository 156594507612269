import React from 'react';
import classes from './SkeletonAddressCard.module.scss';
import addressCardClasses from '../../../pages/Addresses/AddressCard/AddressCard.module.scss';
import Skeleton from '../Skeleton';

export default function SkeletonAddressCard() {
  const items = [1, 2];

  return (
    <div className={`${addressCardClasses.card} ${classes.card}`}>
      <div className={addressCardClasses.cardImgBox}>
        <Skeleton borderRadius='0' width='100%' height='100%' isLight />
      </div>
      <div className={classes.cardInfo}>
        <div className={classes.title}>
          <Skeleton borderRadius='5px' width='100%' height='100%' isLight={false} />
        </div>
        <div className={classes.text}>
          <Skeleton borderRadius='5px' width={120} height={15} isLight={false} />
        </div>
        <div className={classes.title2}>
          <Skeleton borderRadius='5px' width='100%' height='100%' isLight={false} />
        </div>
        <div className={classes.text}>
          <Skeleton borderRadius='5px' width={180} height={15} isLight={false} />
        </div>
        <div className={classes.title3}>
          <Skeleton borderRadius='5px' width='100%' height='100%' isLight={false} />
        </div>
        <div className={classes.text}>
          <Skeleton borderRadius='5px' width='89%' height={15} isLight={false} />
        </div>
        <div className={classes.text}>
          <Skeleton borderRadius='5px' width={180} height={15} isLight={false} />
        </div>
        <div className={classes.boxes1}>
          {items.map((_, index) => (
            <div className={classes.box} key={index}>
              <div className={classes.boxSkeleton}>
                <Skeleton borderRadius='5px' width='100%' height='100%' isLight={false} />
              </div>
            </div>
          ))}
        </div>
        <div className={classes.boxes2}>
          {items.map((_, index) => (
            <div className={classes.box} key={index}>
              <Skeleton borderRadius='5px' width='100%' height={15} isLight={false} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
