import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getBaseUrl } from '../functions/getBaseUrl';

const BASE_URL = getBaseUrl();

export const mainTopSliderAPI = createApi({
  reducerPath: 'mainTopSliderAPI',

  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchMainTopSlider: build.query({
      query: () => ({
        url: '/api/gallery',
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export const mainCategoriesSliderAPI = createApi({
  reducerPath: 'mainCategoriesSliderAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchMainCategoriesSlider: build.query({
      query: () => ({
        url: '/api/category',
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});

export const mainSeasonalSliderAPI = createApi({
  reducerPath: 'mainSeasonalSliderAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchMainSeasonalSlider: build.query({
      query: () => ({
        url: '/api/menu-slider',
      }),
    }),
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
});
