import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import classes from './RestaurantModal.module.scss';
import { GeneralContext } from '../../context/generalContext';
import { addressesAPI, domainAPI } from '../../services/GeneralService';

export default function RestaurantModal() {
  const [, setCookie] = useCookies([]);
  const { subdomainIsActive, selectedAddress } = useContext(GeneralContext);
  const { data: domainData } = domainAPI.useFetchAllDomainQuery();
  const { data: addresses } = addressesAPI.useFetchAllAddressesQuery();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const getModalData = source => {
    if (source?.is_active) {
      setShowModal(true);
      setModalData(source || {});
    } else {
      setShowModal(false);
      setModalData({});
    }
  };

  const onShowModal = () => {
    if (subdomainIsActive) {
      const currAddress = addresses?.find(el => el.id === selectedAddress);
      getModalData(currAddress?.modal);
    } else {
      getModalData(domainData?.modal);
    }
  };
  const onCloseModal = () => {
    setShowModal(false);
    setCookie(['restaurantModal'], true, { maxAge: 86400 });
    document.body.style.overflow = 'visible';
    document.body.style.position = '';
    document.body.style.width = '';
  };

  useEffect(() => {
    setTimeout(() => onShowModal(), 2000);
  }, [subdomainIsActive, addresses, domainData, selectedAddress]);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = 'visible';
      document.body.style.position = '';
      document.body.style.width = '';
    }
  }, [showModal]);

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={onCloseModal}
      contentLabel='My dialog'
      className={classes.modal}
      overlayClassName={classes.overlay}
    >
      <div className={classes.closeArea} onClick={onCloseModal}>
        <div className={classes.closeIcon} />
      </div>
      {modalData?.image && (
        <img src={modalData?.image} alt='изображение для модального окна' className={classes.image} />
      )}
      <div className={modalData?.image ? classes.contentWithImage : classes.contentWithoutImage}>
        {modalData?.title && (
          <div className={classes.title} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modalData?.title) }} />
        )}
        {modalData?.text && (
          <div className={classes.text} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modalData?.text) }} />
        )}
        {modalData?.link && (
          <Link to={modalData?.link} className={classes.link} onClick={onCloseModal}>
            Узнать подробнее
          </Link>
        )}
      </div>
    </Modal>
  );
}
